var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "error-page" }, [
    _vm._m(0),
    _c("div", { staticClass: "error-desc" }, [
      _vm._v("啊哦~ 你所访问的页面不存在"),
    ]),
    _c(
      "div",
      { staticClass: "error-handle" },
      [
        _c(
          "el-button",
          {
            staticClass: "error-btn",
            attrs: { type: "primary", size: "large" },
            on: { click: _vm.goBack },
          },
          [_vm._v("返回上一页")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "error-code" }, [
      _vm._v("\n    4\n    "),
      _c("span", [_vm._v("0")]),
      _vm._v("\n    4\n  "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }